<mat-dialog-content class="mat-typography">
  <div fxLayout="row wrap">
    <div fxFlex="100">
      <div fxFlex="80" style="text-align: left">
        <h3>Edit User</h3>
      </div>
      <div fxFlex="20" style="text-align: right">
        <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
      </div>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput [(ngModel)]="data.user.email" readonly />
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="data.user.first_name" />
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Licenses</mat-label>
        <mat-select [(ngModel)]="data.user.active_licenses_uuid" multiple>
          <mat-option
            *ngFor="let lic of data.licenses"
            [value]="lic.module.application_uuid"
          >
            <div class="d-flex align-items-center lic-card">
              <img
                class="mat-card-avatar"
                [ngClass]="small"
                [src]="lic.module_icon"
              />
              <div>
                <div class="lic-card__name">
                  {{ lic.name }}
                </div>
                <div class="lic-card__description">
                  Expires: {{ lic.expiry }}
                </div>
              </div>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>License Bundles</mat-label>
        <mat-select [(ngModel)]="data.user.license_bundles" multiple>
          <mat-option *ngFor="let lic of data.license_bundles" [value]="lic.id">
            {{ lic.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Role</mat-label>
        <mat-select [(ngModel)]="data.user.role" required>
          <mat-option *ngFor="let role of data.roles" [value]="role.id">
            {{ role.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Mobile</mat-label>
        <ngx-mat-intl-tel-input
          [preferredCountries]="['us', 'gb']"
          [enablePlaceholder]="true"
          [enableSearch]="true"
          name="mobile"
          [(ngModel)]="data.user.mobile"
          #mobile
        >
        </ngx-mat-intl-tel-input>
        <mat-icon matSuffix>phone_iphone</mat-icon>
        <mat-hint *ngIf="mobile.selectedCountry"
          >e.g. {{ mobile.selectedCountry.placeHolder }}</mat-hint
        >
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Telephone</mat-label>
        <ngx-mat-intl-tel-input
          [preferredCountries]="['us', 'gb']"
          [enablePlaceholder]="true"
          [enableSearch]="true"
          name="telephone"
          [(ngModel)]="data.user.telephone"
          #telephone
        >
        </ngx-mat-intl-tel-input>
        <mat-icon matSuffix>phone</mat-icon>
        <mat-hint *ngIf="telephone.selectedCountry"
          >e.g. {{ telephone.selectedCountry.placeHolder }}</mat-hint
        >
      </mat-form-field>
    </div>
    <div fxFlex="100" class="m-t-10">
      <button
        mat-raised-button
        color="primary"
        style="width: 100%"
        [disabled]="!data.user.role || !data.user.first_name"
        (click)="editUser()"
      >
        <mat-icon>person</mat-icon> Update
      </button>
    </div>
  </div>
</mat-dialog-content>
