import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
  	private auth: AuthenticationService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
  	if (request.url.includes('auth') || request.url.includes('sso-dev')){
  		if (request.url.includes('session') || request.url.includes('register') || request.url.includes('temporary')){
  			return next.handle(request);
  		}

  		request = request.clone({
      		withCredentials: true,
      		setHeaders: {
        		Authorization: `JWT ${this.auth.getSessionToken()}`,
      		}
    	});
      
    	return next.handle(request); 
  	}

    if (request.url.includes('release')) {
      return next.handle(request);
    } 

    if (request.url.includes('digitalocean')) {
      return next.handle(request);
    } 
	if (!request.url.includes('unity')){
  		request = request.clone({

      		withCredentials: true,
      		setHeaders: {
        		Authorization: `JWT ${this.auth.getAuthorisationToken()}`,
      		}
    	});}
    	return next.handle(request);
    }
}
