import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import {
  AuthenticationFirstResponse,
  AuthenticationFinalResponse,
  User,
  CompanyUser,
  MasterLicense,
} from '../_models/user_authentication';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { LearningObjective } from '../_models/learningObjective';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private mfaDetails: BehaviorSubject<AuthenticationFirstResponse>;
  currentMFADetails: Observable<AuthenticationFirstResponse>;

  private userDetails: BehaviorSubject<AuthenticationFinalResponse>;
  currentUserDetails: Observable<AuthenticationFinalResponse>;

  constructor(private http: HttpClient) {
    this.mfaDetails = new BehaviorSubject<AuthenticationFirstResponse>(
      JSON.parse(localStorage.getItem('authenticationDetails') || '{}')
    );
    this.currentMFADetails = this.mfaDetails.asObservable();
    this.userDetails = new BehaviorSubject<AuthenticationFinalResponse>(
      JSON.parse(localStorage.getItem('userDetails') || '{}')
    );
    this.currentUserDetails = this.userDetails.asObservable();
  }

  fileUploadService(file: File, url: string, method?: string) {
    let fileName = file.name;
    const formData = new FormData();
    formData.append('file', file);
    if (method && method === 'put') {
      return this.http
        .put<any>(`${environment.authUrl}/${url}/`, formData)
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => throwError(error || 'Something went wrong!'))
        );
    } else {
      return this.http
        .post<any>(`${environment.authUrl}/${url}/`, formData)
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => throwError(error || 'Something went wrong!'))
        );
    }
  }

  addUserAssetDoc(
    name: string,
    description: string,
    extension: string,
    application_id: number | null,
    company_id: number | null,
    default_issue: boolean | false
  ) {
    return this.http
      .post<any>(`${environment.authUrl}/v1/license_management/document/`, {
        name,
        description,
        extension,
        application_id,
        company_id,
        default_issue,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  updateUserAssetDoc(
    name: string,
    description: string,
    extension: string,
    application_id: number | null,
    id: string,
    company_id: number | null,
    default_issue: boolean | false
  ) {
    return this.http
      .put<any>(
        `${environment.authUrl}/v1/license_management/document/${id}/`,
        {
          name,
          description,
          extension,
          application_id,
          company_id,
          default_issue,
        }
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  deleteUserAssetDoc(id: string) {
    return this.http
      .delete<any>(
        `${environment.authUrl}/v1/license_management/document/${id}/`
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  changeMFADetails(unit: any) {
    this.mfaDetails.next(unit);
    localStorage.setItem('authenticationDetails', JSON.stringify(unit));
  }

  changeUserDetails(unit: any) {
    this.userDetails.next(unit);
    localStorage.setItem('userDetails', JSON.stringify(unit));
  }

  createUser(users: any[]) {
    return this.http.post<any>(`${environment.authUrl}/v1/users/`, users).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => throwError(error || 'Something went wrong!'))
    );
  }

  getUserRoles() {
    return this.http
      .get<any>(`${environment.authUrl}/v1/company/roles_list/`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || "Something went wrong!"))
      );
  }
  
  getCompanies() {
    return this.http
    .get<any>(`${environment.authUrl}/v1/company/companies/`)
    .pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => throwError(error || "Something went wrong!"))
    );
  }

  getApplications(): any {
    return this.http
      .get<CompanyUser[]>(`${environment.authUrl}/v1/company/applications/`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || "Something went wrong!"))
      );
  }

  ssaiUserRegister(user: any) {
    return this.http.post<any>(`${environment.authUrl}/v1/ssai_create_user/`, user).pipe(
      map((response)=> {
        return response
      })
    )
  }

  createCompany(name: string, max_users: string) {
    return this.http
      .post<any>(`${environment.authUrl}/v1/company/`, { name, max_users })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  getDocument(company_id: number, type: string, limit: number, offset: number) {
    return this.http
      .get<any>(
        `${environment.authUrl}/v1/company/document/${company_id}/?type=${type}&limit=${limit}&offset=${offset}`,
        {}
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  addDocument(
    name: string,
    description: string,
    company_id: number | null,
    extension: string,
    application_id: number | null,
    elearning_id: number | null
  ) {
    return this.http
      .post<any>(`${environment.authUrl}/v1/company/document/`, {
        name,
        description,
        company_id,
        extension,
        application_id,
        elearning_id,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  getElearningContent(limit: number, offset: number, query: any = null) {
    return this.http
      .get<any>(
        `${environment.authUrl}/v1/company/elearningapplication/?limit=${limit}&offset=${offset}&query=${query}`
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }
  getDocumentation() {
    return this.http
      .get<any>(`${environment.authUrl}/v1/company/documentation/`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  getElearningDetails(id: string) {
    return this.http
      .get<any>(`${environment.authUrl}/v1/company/elearningapplication/${id}/`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  createContent(name: string) {
    return this.http
      .post<any>(`${environment.authUrl}/v1/company/content/`, { name })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }
  deleteContent(id: any) {
    return this.http
      .delete<any>(`${environment.authUrl}/v1/company/content/${id}/`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  deleteDocument(uuid: string) {
    return this.http
      .delete<any>(`${environment.authUrl}/v1/company/document/${uuid}/`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  uploadDocument(url: string, file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.http.put<any>(url, formData).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => throwError(error || 'Something went wrong!'))
    );
  }

  getDashboard() {
    return this.http
      .get<any>(`${environment.authUrl}/v1/app_sink/dashboard/`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  getDashboardData() {
    return this.http
      .get<any>(`${environment.authUrl}/v1/app_sink/dashboard/data/`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  getDashboardDataByCompAppId(id: number, appId: number) {
    return this.http
      .get<any>(`${environment.unityUrl}/datadashboard/${id}/${appId}/`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  getDashboardDataById(id: number) {
    return this.http
      .get<any>(`${environment.unityUrl}/datadashboard/${id}/`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  getCosting(id: number | null) {
    if (id) {
      return this.http
        .get<any>(`${environment.authUrl}/v1/company/costing/${id}`)
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => throwError(error || 'Something went wrong!'))
        );
    } else {
      return this.http
        .get<any>(`${environment.authUrl}/v1/company/costing/`)
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => throwError(error || 'Something went wrong!'))
        );
    }
  }

  getPlatformSettings(id: number | null) {
    if (id) {
      return this.http
        .get<any>(`${environment.authUrl}/v1/company/platform/${id}/`)
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => throwError(error || 'Something went wrong!'))
        );
    } else {
      return this.http
        .get<any>(`${environment.authUrl}/v1/company/platform/`)
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => throwError(error || 'Something went wrong!'))
        );
    }
  }

  clientOrders(
    data: any | null,
    id: number | null,
    company_id: number | null,
    query: string = null
  ) {
    if (!data && !id) {
      return this.http
        .get<any>(
          `${environment.authUrl}/v1/company/accounts/recieveable/?query=${query}`
        )
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => throwError(error || 'Something went wrong!'))
        );
    } else if (!data && id) {
      return this.http
        .get<any>(
          `${environment.authUrl}/v1/company/accounts/recieveable/${id}/?query=${query}`
        )
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => throwError(error || 'Something went wrong!'))
        );
    } else if (data && id) {
      return this.http
        .put<any>(
          `${environment.authUrl}/v1/company/accounts/recieveable/${id}/?query=${query}`,
          {
            data,
            company_id,
          }
        )
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => throwError(error || 'Something went wrong!'))
        );
    } else {
      return this.http
        .post<any>(
          `${environment.authUrl}/v1/company/accounts/recieveable/${company_id}/?query=${query}`,
          { data, company_id }
        )
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => throwError(error || 'Something went wrong!'))
        );
    }
  }

  deleteClientOrders(id: number) {
    return this.http
      .delete<any>(
        `${environment.authUrl}/v1/company/accounts/recieveable/${id}/`
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  supplierOrders(
    data: any | null,
    id: number | null,
    company_id: number | null,
    limit: number,
    offset: number
  ) {
    if (!data && !id) {
      return this.http
        .get<any>(
          `${environment.authUrl}/v1/company/accounts/payable/?limit=${limit}&offset=${offset}`
        )
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => throwError(error || 'Something went wrong!'))
        );
    } else if (!data && id) {
      return this.http
        .get<any>(
          `${environment.authUrl}/v1/company/accounts/payable/${id}/?limit=${limit}&offset=${offset}`
        )
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => throwError(error || 'Something went wrong!'))
        );
    } else if (data && id) {
      return this.http
        .put<any>(`${environment.authUrl}/v1/company/accounts/payable/${id}/`, {
          data,
          company_id,
        })
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => throwError(error || 'Something went wrong!'))
        );
    } else {
      return this.http
        .post<any>(
          `${environment.authUrl}/v1/company/accounts/payable/${company_id}/`,
          {
            data,
            company_id,
          }
        )
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => throwError(error || 'Something went wrong!'))
        );
    }
  }

  deleteSupplierOrder(id: number) {
    return this.http
      .delete<any>(`${environment.authUrl}/v1/company/accounts/payable/${id}/`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  createSupplier(name: string) {
    return this.http
      .post<any>(`${environment.authUrl}/v1/company/suppliers/`, { name })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  deleteSupplier(id: number) {
    return this.http
      .delete<any>(`${environment.authUrl}/v1/company/suppliers/${id}/`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  createClient(name: string) {
    return this.http
      .post<any>(`${environment.authUrl}/v1/company/clients/`, { name })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  modifySuppliers(
    name: string,
    company_categories: string[],
    supplier_of_array: string[],
    terms_name_array: string[],
    company_id: number
  ) {
    return this.http
      .put<any>(`${environment.authUrl}/v1/company/suppliers/${company_id}/`, {
        name,
        company_categories,
        supplier_of_array,
        terms_name_array,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  modifyClient(
    name: string,
    company_categories: string[],
    payment_method: string[],
    max_users: number,
    credit_limit: number,
    company_id: number
  ) {
    return this.http
      .put<any>(`${environment.authUrl}/v1/company/clients/${company_id}/`, {
        name,
        company_categories,
        payment_method,
        max_users,
        credit_limit,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  getContact(conatct_id: any, limit: number, offset: number) {
    return this.http
      .get<any>(
        `${environment.authUrl}/v1/company/contact/${conatct_id}/?limit=${limit}&offset=${offset}`
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }
  getAllContact(limit: number, offset: number) {
    return this.http
      .get<any>(
        `${environment.authUrl}/v1/company/contact/?limit=${limit}&offset=${offset}`
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  modifyContact(
    data: any,
    company_id: number,
    new_user: boolean,
    delete_user: boolean
  ) {
    if (new_user) {
      const requestPayload = {
        data,
        company_id,
      };
      return this.http
        .post<any>(`${environment.authUrl}/v1/company/contact/`, requestPayload)
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => throwError(error || 'Something went wrong!'))
        );
    } else if (delete_user) {
      return this.http
        .delete<any>(`${environment.authUrl}/v1/company/contact/${data.id}/`)
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => throwError(error || 'Something went wrong!'))
        );
    } else {
      return this.http
        .put<any>(`${environment.authUrl}/v1/company/contact/${data.id}/`, {
          data,
          company_id,
        })
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => throwError(error || 'Something went wrong!'))
        );
    }
  }

  getSuppliers(query: any = null) {
    return this.http
      .get<any>(`${environment.authUrl}/v1/company/suppliers/?query=${query}`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  getClients(limit: number, offset: number, query: any = null) {
    return this.http
      .get<any>(
        `${environment.authUrl}/v1/company/clients/?limit=${limit}&offset=${offset}&query=${query}`
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  suspendUser(id: string, action: number) {
    return this.http
      .delete<any>(`${environment.authUrl}/v1/users/${id}/${action}/`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  resendWelcome(id: string) {
    return this.http
      .post<any>(`${environment.authUrl}/v1/company/user/`, { id })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  updateUserCompany(
    id: string,
    first_name: string,
    role: string,
    licenses: string[] | null,
    mobile: string | null,
    telephone: string | null,
    license_bundles: any[] | null
  ) {
    return this.http
      .put<any>(`${environment.authUrl}/v1/company/user/${id}/`, {
        first_name,
        role,
        licenses,
        mobile,
        telephone,
        license_bundles,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  updateUserAvatar(formData: FormData) {
    return this.http
      .post<any>(`${environment.authUrl}/v1/company/avatars/`, formData)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  getCompanyUsers(
    company_id: number,
    limit: number,
    offset: number,
    query: string = null
  ) {
    return this.http
      .get<any>(
        `${environment.authUrl}/v1/users/${company_id}/${company_id}/?limit=${limit}&offset=${offset}&query=${query}`
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  getActiveLicense(limit: number, offset: number, query: any = null): any {
    return this.http
      .get<CompanyUser[]>(
        `${environment.authUrl}/v1/users/active_license/?limit=${limit}&offset=${offset}&query=${query}`
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  userActiveLicense(user_id: number): any {
    return this.http
      .get<CompanyUser[]>(
        `${environment.authUrl}/v1/users/active_license/${user_id}/`
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  updateBundle(
    uuid: string,
    major_version_number: number,
    minor_version_number: number
  ) {
    return this.http
      .put<any>(
        `${environment.authUrl}/v1/license_management/assetbundles/${uuid}/`,
        {
          major_version_number,
          minor_version_number,
        }
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  updateContent(
    uuid: string,
    name: string,
    description: string,
    repo: string,
    vr_mode: boolean,
    is_landscape: boolean,
    container_display: boolean,
    devices: string[]
  ) {
    return this.http
      .put<any>(`${environment.authUrl}/v1/company/content/${uuid}/`, {
        name,
        description,
        repo,
        vr_mode,
        is_landscape,
        container_display,
        devices,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  updateElearningContent(
    id: string,
    name: string,
    description: string,
    lms_url: string
  ) {
    return this.http
      .put<any>(
        `${environment.authUrl}/v1/company/elearningapplication/${id}/`,
        {
          name,
          description,
          lms_url,
        }
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  createElearningContent(name: string) {
    return this.http
      .post<any>(`${environment.authUrl}/v1/company/elearningapplication/`, {
        name,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  deleteElearningContent(id: any) {
    return this.http
      .delete<any>(
        `${environment.authUrl}/v1/company/elearningapplication/${id}/`
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  refreshUserData() {
    return this.http.get<any>(`${environment.authUrl}/v1/users/`).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => throwError(error || 'Something went wrong!'))
    );
  }

  getModuleListing(limit: number, offset: number, query: any = null) {
    return this.http
      .get<any>(
        `${environment.authUrl}/v1/license_management/modules/?limit=${limit}&offset=${offset}&query=${query}`
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  getCompanyListings() {
    return this.http.get<any>(`${environment.authUrl}/v1/company/`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getCompanyDetails(id: string) {
    return this.http.get<any>(`${environment.authUrl}/v1/company/${id}/`).pipe(
      map((response) => {
        return response;
      })
    );
  }
  getCompanyNotes(id: string) {
    return this.http
      .get<any>(`${environment.authUrl}/v1/company/notes/${id}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getContentListings(limit: number, offset: number, query: any = null) {
    return this.http
      .get<any>(
        `${environment.authUrl}/v1/company/content/?limit=${limit}&offset=${offset}&query=${query}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getContentDetail(id: string) {
    return this.http
      .get<any>(`${environment.authUrl}/v1/company/content/${id}/`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  getAssetsBundle(id: string, limit: number, offset: number) {
    return this.http
      .get<any>(
        `${environment.authUrl}/v1/company/application/${id}/assets/?limit=${limit}&offset=${offset}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getJAWUsers() {
    return this.http
      .get<any>(
        'https://release.xrlplatform.com/api/v3/cd1c9476-8dbd-11eb-8dcd-0242ac130003/'
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getJAWData() {
    return this.http
      .get<any>(
        'https://release.xrlplatform.com/api/v3/bb9f9d8a-92de-11eb-a8b3-0242ac130003/'
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getJAWDashboard() {
    return this.http
      .get<any>(
        'https://release.xrlplatform.com/api/v3/bb9f9d8a-92de-11eb-a8b3-0242ac130004/'
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  companyUserDetails(): any {
    return this.http
      .get<CompanyUser[]>(`${environment.authUrl}/v1/users/`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  companyMasterLicense(id: number | null): any {
    if (id) {
      return this.http
        .get<MasterLicense[]>(
          `${environment.authUrl}/v1/license_management/masterlicense/${id}/`
        )
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => throwError(error || 'Something went wrong!'))
        );
    } else {
      return this.http
        .get<MasterLicense[]>(
          `${environment.authUrl}/v1/license_management/masterlicense/`
        )
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => throwError(error || 'Something went wrong!'))
        );
    }
  }

  updateUserAPI(unit: User, self: boolean): any {
    if (self) {
      return this.http
        .put<User>(`${environment.authUrl}/v1/users/`, { unit })
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => throwError(error || 'Something went wrong!'))
        );
    } else {
    }
  }
  /**
   * 
   */
  
  // getPronunciationAssessment(
  //   app_id: number | null,
  //   company_id: number | null,
  //   type: string | null,
  //   page: number | 0
  // ) {
  //   return this.http
  //     .post<any>(`${environment.pronUrl}/get_assessment/`, {
  //       app_id,
  //       company_id, 
  //       type,
  //       page
  //     })
  //     .pipe(
  //       map((response) => {
  //         return response;
  //       }),
  //       catchError(this.handleError<any>('getPronunciationAssessment'))
  //     );
  //   }

  /**
   * This API is used to fetch learningObjectives
   *
   * @param {{
   *     company_id: number;
   *     application_ids: number[];
   *     page_size?: number;
   *     offset?: any;
   *     user_id?: number;
   *   }} params
   * @return {*}  {Observable<LearningObjective[]>}
   *
   * @memberof UserService
   */
  getLearningObjective(
    params: Partial<{
      company_id: any;
      application_ids: any[];
      page_size?: any;
      offset?: any;
      user_id?: any;
      group_ids?: any[];
    }>
  ): Observable<LearningObjective[]> {
    let { offset, ...rest } = params;
    return this.http
      .post<LearningObjective[]>(
        `${environment.authUrl}/app_sink/get_learning_objectives/`,
        rest,
        { params: { offset } }
      )
      .pipe(
        map((data: any) => {
          if (!data.constants) {
            const constants = data.constants || {};
            data.constants = Object.assign({}, constants, {
              learning_objective_constants:
                constants.learning_objective_constants || {},
              slug: constants.slug || '',
              result_constants: constants.slug || {},
              skip_dashboard_columns: constants.skip_dashboard_columns || [],
            });
          }
          return data;
        }),
        catchError(this.handleError<any>('getLearningObjective'))
      );
  }

  /**
   * This API is used to fetch userCompletion
   *
   * @param {{
   *     company_id: number;
   *     application_ids: number[];
   *     page_size?: number;
   *     offset?: any;
   *     user_id?: number;
   *   }} params
   * @return {*}  {Observable<LearningObjective[]>}
   *
   * @memberof UserService
   */
  getUserCompletion(params: {
    company_id: number;
    application_ids: number[];
    page_size?: number;
    offset?: any;
    user_id?: number;
    group_ids?: number[];
  }): Observable<LearningObjective[]> {
    const { offset, ...rest } = params;
    return this.http
      .post<LearningObjective[]>(
        `${environment.authUrl}/app_sink/get_user_completion/`,
        rest,
        { params: { offset } }
      )
      .pipe(catchError(this.handleError<any>('getUserCompletion')));
  }

  getLeaderboard(params: {
    company_id: number;
    application_ids: number[];
    page_size?: number;
    offset?: any;
    user_id?: number;
    group_ids?: number[];
  }): Observable<LearningObjective[]> {
    const { offset, ...rest } = params;
    return this.http
      .post<LearningObjective[]>(
        `${environment.authUrl}/app_sink/get_leaderboard/`,
        rest,
        { params: { offset } }
      )
      .pipe(catchError(this.handleError<any>('getLeaderboard')));
  }

  /**
   * This API is used to fetch stats and chart data for dashboard
   *
   * @param {{
   *     user_id?: number;
   *     company_id?: number;
   *     application_ids?: number[];
   *   }} params
   * @return {*}  {Observable<any>}
   * @memberof UserService
   */
  getUserStats(params: {
    user_id?: number;
    company_id?: number;
    application_ids?: number[];
    group_ids?: number[];
  }): Observable<any> {
    return this.http
      .post<any>(`${environment.authUrl}/app_sink/dashboard/`, params)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  /**
   * Handle Http method that failed.
   * Let the app continue.
   *
   * @param method - name of the method that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(method = 'method', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${method} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
